import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_PROVIDER_VOUCHER_ALLOCATION, propTypes } from '../../util/types';
import { voucherPremiumPercentage } from '../../config/configPayout'
import Pricing from '../../util/pricingFunction';
import { resolveLatestProcessName, getProcess } from '../../transactions/transaction';


const { types } = require('sharetribe-flex-sdk');

const { Money } = types;

import css from './OrderBreakdown.module.css';

const LineItemTotalVoucher = props => {
  const { lineItems, transaction, isVoucherPayout, intl } = props;

  const voucherAllocationLineItem = lineItems.find(
    item => item.code === LINE_ITEM_PROVIDER_VOUCHER_ALLOCATION && !item.reversal
  );
  if (!voucherAllocationLineItem) return null; // Early return if no voucher allocation line item found

  const processName = resolveLatestProcessName(transaction?.attributes?.processName);
  if (!processName) {
    return null;
  }
  const process = getProcess(processName);
  const isCompleted = process.isCompleted(transaction?.attributes?.lastTransition);
  const isRefunded = process.isRefunded(transaction?.attributes?.lastTransition);

  let providerTotalMessageId = 'OrderBreakdown.providerVoucherTotalDefault';
  if (isCompleted) {
    providerTotalMessageId = 'OrderBreakdown.providerVoucherTotalReceived';
  } else if (isRefunded) {
    providerTotalMessageId = 'OrderBreakdown.providerVoucherTotalRefunded';
  }

  const totalLabel =  <FormattedMessage id={providerTotalMessageId} />;


  const basePayout = -voucherAllocationLineItem.lineTotal.amount / 100;  // sign reverse the negative voucherAllocation line item; convert to EUR
  const voucherTotalAmount = parseFloat(Pricing.calculatePayoutWithPremium(basePayout, voucherPremiumPercentage, true, 5));
  const voucherTotalAmountProc = voucherTotalAmount * 100;  // convert back to cents
  const voucherTotal = new Money(voucherTotalAmountProc, voucherAllocationLineItem.lineTotal.currency);

  return isVoucherPayout ? (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <span className={css.totalLabel}>{totalLabel}</span>
        <span className={css.totalPrice}>{formatMoney(intl, voucherTotal)}</span>
      </div>
    </>
  ) : null;
};

LineItemTotalVoucher.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  isVoucherPayout: bool,
  intl: intlShape.isRequired,
};

export default LineItemTotalVoucher;
