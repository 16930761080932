import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';
import { GoogleAnalyticsHandler } from '../../analytics/handlers';
import { googleAnalyticsEnabled } from '../../config/configAnalytics';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './ListingCard.module.css';
import { getVariantPrices } from '../../util/genericHelpers';
import { brandNameConverter } from '../../util/string';
import { displayExtraInfo } from '../../config/configLayout';

import { multilanguageListingTitle } from '../../util/translationUtils';
import { languagesHelper as language } from '../../helpers/languages';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType, originalGtPrice } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }
  const { Money } = sdkTypes;

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);

  let originalPriceFormatted = null;
  if (typeof originalGtPrice === 'number') {
    const originalPriceMoney = new Money(originalGtPrice, config.currency);
    originalPriceFormatted = formatMoney(intl, originalPriceMoney);
  }

  const renderPrice = getVariantPrices(intl, publicData, config.currency) || formattedPrice;

  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {renderPrice}
      </div>
      {originalPriceFormatted && (
        <div className={css.originalPriceValue}>{originalPriceFormatted}</div>
      )}
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const aspectWidth = 1;
  const aspectHeight = 1;

  const { variantPrefix = 'listing-card' } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  // Create an instance of GoogleAnalyticsHandler
  const analyticsHandler = new GoogleAnalyticsHandler();

  // Event handler for click events
  const handleClick = () => {
    if (googleAnalyticsEnabled == true) {
      analyticsHandler.trackCustomEvent('ShopSelectItem');
    }
  };

  let currentLocale = language.get();
  const customListingTitle = multilanguageListingTitle(currentListing, currentLocale);

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }} onClick={handleClick}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={classNames(css.rootForImage)}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        <div className={css.mainInfo}>
          {displayExtraInfo.displaySubBrands ? (
            <div className={css.brandName}>
              {brandNameConverter(listing?.attributes?.publicData?.brandChild)}
            </div>
          ) : null}
          <div className={css.title}>
            {richText(customListingTitle, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
            <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
